import { Link } from "gatsby"
import React from "react"
import { FaSearch } from "react-icons/fa";
import firebase from '../../src/components/auth/fire-base.js';

class SignedOutHeader extends React.Component {

  constructor(props) {
    super(props)
    this.searchBar = React.createRef();
    this.displayError = React.createRef();
  }

  componentDidMount() {
    if(typeof window !== `undefined`) {
      firebase.auth().onAuthStateChanged((user) => {
        if (user !== null) {
         if(window.location.pathname === "" || window.location.pathname === "/") {
            window.location.href = "/home";
         }
        }
      })
    }
  }

  search = async event => {
    event.preventDefault();
    if (this.searchBar.current.value.trim() !== "") {
        await firebase.firestore().collection("pins").doc(this.searchBar.current.value).get()
            .then(doc => {
                if (doc.exists) {
                   window.location.href = "/search?pincode=" +  this.searchBar.current.value;
                   this.displayError.current.hidden = true;
                   this.searchBar.current.style.border = "";
                }
                else {
                  this.displayError.current.hidden = false;
                  this.searchBar.current.style.border = "3px solid red";
                  this.displayError.current.innerHTML = "PIN not found, please try again";
                }
            })
            .catch(err => {
                console.log(err);
            });
    }
    else {
         this.displayError.current.innerHTML = "Please enter a value";
         this.searchBar.current.style.border = "3px solid red";
         this.displayError.current.hidden = false;
      }
  }

  render() {
    return (
      <div className="search-bar-wrapper col-md-12">
           <form className = "search-form" onSubmit={this.search}> 
              <div className = "search-bar2">
              <div className = "gray-search-btn" style={{ padding: '8px', float:"left", backgroundColor: 'rgb(244,244,244)', borderRadius: '4px 0px 0px 4px' }}>
                  <FaSearch />
              </div>
              <input type="text" id="search-bar" className="search-bar" placeholder="  Search by PIN" ref={this.searchBar}></input>
              <button type="submit" className="orange-search-btn" style={{ width: "78px", height: "43px", backgroundColor: "#e8573a", float:"left" }}>
                  <FaSearch icon="search" style={{ color: "fff" }} />
              </button>
              </div>
              <Link to="/signin"><button className = "sign-in button"> Sign In</button></Link>
              <Link to="/signup"><button className = "sign-up button"> Sign Up</button></Link>
              <p style={{ color: "red" }} hidden id="error" ref={this.displayError}></p>
          </form>
      </div>
    )
  }
}



export default SignedOutHeader
