import React  from "react"
import { Link } from "gatsby"
import Logo from "../images/orange.png"


const SignedOutSidebar = () => {
   return (
  <>
    <div id="home-sidebar" className = "app-sidebar"> 
    <div className = "tab logo-wrapper home-title"><img src = {Logo} className = "logo"/> Cloudpin </div>
    <div className = "tab home-header">CONNECT WITH THE PEOPLE AROUND YOU</div>
    <div className = "tab"><Link to="/signup"> <button className="home-signup-btn"> Sign Up</button></Link></div>
    
    </div>   
    

</>
   );
   
};



export default SignedOutSidebar
