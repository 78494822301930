import React from "react"
import Sidebar from "react-sidebar"
import DesktopSidebar from "./sidebar"
import { IoMdMenu } from "react-icons/io";
import Header from "./header"
import NotFoundImage from "../images/emptyState.png"
import SignedOutSidebar from "./signed-out-sidebar"
import SignedOutHeader from "./signed-out-header"
import firebase from "./auth/fire-base.js"
import "firebase/auth"
require('./layout.css')

class NotFoundComponent extends React.Component {
  constructor(props) {
     super(props)
     this.state = {
       sidebarOpen: false,
       hasUser: false
     }
     this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this)
   }

   componentDidMount() {
     this.getUser()
     setTimeout(()=>{ this.showComponents() }, 500)
   }

   showComponents() {
     document.getElementById("side-bar").hidden = false
     document.getElementById("header").hidden = false
   }

   async getUser() {
    await firebase.auth().onAuthStateChanged((user)=> {
      if (user) {
        // User is signed in.
       this.setState({hasUser: true})
      } else {
        // No user is signed in.
      }
    })
  }
   onSetSidebarOpen(open) {
     this.setState({ sidebarOpen: open })
   }

  render() {
     return (
           <Sidebar
             sidebar={this.state.hasUser ? <DesktopSidebar> </DesktopSidebar> : <SignedOutSidebar></SignedOutSidebar>}
             open={this.state.sidebarOpen}
             onSetOpen={this.onSetSidebarOpen}
             styles={{ sidebar: { background: "white" } }}
           >
            {this.state.hasUser ? (
           <div className = "sidebar-btn">
           <IoMdMenu size={24} onClick={() => this.onSetSidebarOpen(true)}/>
           </div>) : (<div></div>)}

             <div className="d-flex">
             <div id="side-bar" hidden>
              {this.state.hasUser ? (
                <div className="desktop-only">
                  <DesktopSidebar> </DesktopSidebar>
                </div>
              ) : (
                <div className="desktop-only">
                  <SignedOutSidebar> </SignedOutSidebar>
                </div>
              )}
              </div>
               <div className="" style={{ width: "100%" }}>
               <div id="header" hidden>
                 {this.state.hasUser ? (<Header></Header>) : (<SignedOutHeader></SignedOutHeader>)}
               </div>
                 <div className="content-wrapper">
                   <div className="row">
                     <div className="form-group text-center" style={{ marginLeft: "-10px", width:"100%", paddingTop: "25px", alignContent: "center"}}>
                       <img src={NotFoundImage} alt=""/>
                       <h1>404</h1>
                       <p className="auth-text offset-md-1 col-md-10">{"This isn't the page you're looking for. Try entering a pin above."}</p>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </Sidebar>
         )
    }
}

export default NotFoundComponent